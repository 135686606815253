import http from '@/api/http'
import p from '@/common/privilege'

export default {
  getInfo () {
    return http.get('/appointment/auth/user')
  },
  logout () {
    return http.get(p.OAuthExitUrl)
  }
}
